/*:root {*/
/*  margin: 0;*/
/*  font-family:*/
/*    QuickSand,*/
/*    -apple-system,*/
/*    BlinkMacSystemFont,*/
/*    "Segoe UI",*/
/*    "Roboto",*/
/*    "Oxygen",*/
/*    "Ubuntu",*/
/*    "Cantarell",*/
/*    "Fira Sans",*/
/*    "Droid Sans",*/
/*    "Helvetica Neue",*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/
/**/
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* used for debugging layouts*/
/** {*/
/*  border: 1px solid red !important;*/
/*}*/

.chakra-accordion__item {
  margin-bottom: 10px;
}

.chakra-collapse {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}
